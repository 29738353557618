import axios from "axios";
import Cookies from "universal-cookie";
import { APP_URL } from "../../Config/AppConfiguration";
import login from "./Login";
import { ToastContainer, toast, Slide } from 'react-toastify';


let apimErrorShown = false;

const AppAuthentication = () => {
  const cookies = new Cookies();

  
  axios.interceptors.request.use((config) => {
    if (config.url.includes(APP_URL)) {
      const token = cookies.get("accessToken");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
    }
    return config;
  });

  
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      
      if (error.response?.status === 401) {
        alert(
          "Your access token has expired, you will be re-directed to the login page"
        );
        window.location.href = login.getLoginUrl();
      } 
      // Handle APIM-5000 error
      else if (error.response?.data?.code === "APIM-5000") {
        // Only show toast if it hasn't been shown before
        if (!apimErrorShown) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 8000, 
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            transition: Slide,
          });
          apimErrorShown = true; 
        }
      }
      return Promise.reject(error); 
    }
  );

 
  return (
    <div className="toast-container">
      <ToastContainer style={{ zIndex: 9999 }} limit={1} />
    </div>
  );
};

export default AppAuthentication;
