import React, { useState, useEffect,useContext,useRef } from 'react';
import {SIGNUP_URL,SIGNUP_STATIC_TOKEN, SIGNUP_USER_API,SIGNUP_RESET_PASSWORD_API,RECAPTCHA_SITE_KEY} from '../../Config/AppConfiguration';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import  ReCAPTCHA  from 'react-google-recaptcha';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import './JoinUs.css';
const countryCodes = require('country-codes-list')

const { myCountryCodesObject, SetMyCountryCodesObject } = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}')

var FormFields = {
    Email: {
      label: "emailIdJU",
      regex:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    },
    Mobile: {
      label: "mNumberJU",
      regex: /^[0-9]{1,15}$/,
    },
    DisplayName: {
      label: "dNameJU",
      regex: /^([\wÀ-ÖØ-öø-ÿ!@#,\-\&\)\(\|\<\>\^\$\.\}\{\?]\s?){1,240}$/,
    },
  };

const JoinUs = () => {

    const [emailId, setEmailId] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const[isFormFieldsValid,setIsFormFieldsValid] = useState('false')
    const [captchaStatus, setCaptchaStatus] = useState({ success: false, message: '' })
    const [formValues, setFormValues] = useState({});
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [isCreateButtonActive, setIsCreateButtonActive] = useState(false);
  const [accountActivationConfirmationMsg, setAccountActivationConfirmationMsg] = useState("");


  const navigate = useNavigate();
  const navigateHome = () => {
    navigate('/');
  };
  let captchaReference = useRef()
  
    console.log("PHONE NUMBER ---"+phoneNumber)
    console.log("emailId ---"+emailId)
    console.log("countryCode ---"+countryCode)
    console.log("displayName ---"+displayName)
    console.log('COUNTRY CODES JJASON FORMAT'+countryCodes.code);
     // create a reset function
  const resetRecaptcha = () => {
    captchaReference.reset();
  };
    function onChange(value) {
        console.log("Captcha value:", value);
        setRecaptchaValue(value);
       if(emailId!==null && displayName!==null && countryCode !==null && phoneNumber!==null)
       {setIsFormFieldsValid(true)} 
      }
      console.log("isformfield valid" +isFormFieldsValid);
      console.log("recaptcha response===>"+recaptchaValue);
      let userDetailsObject = {
        displayName: '',
        email: '',
        mobile:''
      };
      function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
            backdrop='static' 
            keyboard="False"
            dialogClassName='my-modal'
           

    
          >
            <Modal.Header className='modal-header'>
              <Modal.Title id="contained-modal-title-vcenter">
              Confirmation Of Account Creation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
              <p>
                {"Account is successfully created, "+accountActivationConfirmationMsg}
              </p>
            </Modal.Body>
            <Modal.Footer>
            <button className="ui button comm-button-style primary" onClick={navigateHome}>OK</button>
            </Modal.Footer>
          </Modal>
        );
      }
      const handleSubmit = async (e) => {
        e.preventDefault();
        setIsCreateButtonActive(true);
        userDetailsObject.displayName=displayName;
        userDetailsObject.email=emailId;
        userDetailsObject.mobile = '+'.concat(countryCode);
        console.log("userDetailsObject.mobile------>"+userDetailsObject.mobile);
        try {
            const response=await  axios.post(SIGNUP_USER_API,userDetailsObject,{ headers: {'Content-Type': 'application/json','g-recaptcha-response':recaptchaValue,Authorization: 'Bearer ' +SIGNUP_STATIC_TOKEN} })
            if(response){
              setAccountActivationConfirmationMsg(response.data.message);
              setIsCreateButtonActive(false); 
              setModalShow(true);
              resetRecaptcha();
            }
          } catch (error) {
            console.log(error);
          }
      }

        return (
          <div
            id="joinUsContainer"
            className="container-fluid"
            style={{ height: "100%" }}
          >
            <div
              className="row"
              style={{ minHeight: "110px", backgroundColor: "white" }}
            >
              <div className="empty-cont-header" style={{ minHeight: "10px" }}>
                <h1>JOIN US</h1>
              </div>
            </div>

            <div className="main-content">
              <section className="join-us-wrap">
                <ul className="signup-elements-wrap mt-2">
                  <li>
                    <div className="custom-input-wrapp">
                      <FloatingLabel
                        controlId="emailIdJU"
                        label="Email Id"
                        className="inputText"
                        autoComplete="off"
                        onChange={(event) => setEmailId(event.target.value)}
                      >
                        <Form.Control
                          type="email"
                          placeholder="name@example.com"
                        />
                        <span className="input-info-label">
                          Eg: username@domain.com
                        </span>
                        <span className="required-field-op">(Required)</span>
                        <span
                          className="form-error error-message-show"
                          style={{ visibility: "hidden" }}
                        >
                          Email Id is invalid
                        </span>
                      </FloatingLabel>
                    </div>
                  </li>
                  <li>
                    <div className="custom-input-wrapp">
                      <FloatingLabel
                        controlId="dNameJU"
                        label="Display Name"
                        className="inputText"
                        autoComplete="off"
                        onChange={(event) => setDisplayName(event.target.value)}
                      >
                        <Form.Control
                          type="name"
                          placeholder="name@example.com"
                        />
                        <span className="input-info-label">
                          Eg: Username OR Username1234
                        </span>
                        <span className="required-field-op">(Required)</span>
                        <span
                          className="form-error error-message-show"
                          style={{ visibility: "hidden" }}
                        >
                          Email Id is invalid
                        </span>
                      </FloatingLabel>
                    </div>
                  </li>
                  <li>
                    <div className="contact-num-wrap pe-2">
                      <div class="country-code-w">
                        {!countryCode ? (
                          <span className="countryCode-label">
                            Select Country Code
                          </span>
                        ) : (
                          <></>
                        )}
                        <PhoneInput
                          className="mt-1"
                          country={""}
                          value={countryCode}
                          enableSearch={true}
                          onChange={(code) => setCountryCode(code)}
                          placeholder="Enter phone number"
                          inputProps={{
                            required: true,
                          }}
                        />
                        <span className="required-field-op">(Required)</span>
                      </div>
                    </div>
                    <span
                      className="form-error error-message-show"
                      style={{ visibility: "hidden" }}
                    >
                      Mobile number is invalid
                    </span>
                  </li>
                  <li className="custom-input-wrapp">
                    <div id="captcha_joinus_element">
                      <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      // sitekey={"6Ldl6MwZAAAAAD8KlVqTRNxW63kBJD43BTbVDvwE"}
                      onChange={onChange}
                        ref={(e) => (captchaReference = e)}
                      />
                    </div>
                  </li>

                  <p>{captchaStatus.message}</p>
                  <li
                    className="min-marg privacy-policy-txt"
                    style={{ display: "none" }}
                  >
                    <p>
                      By signing up you are indicating that you have read &amp;
                      agree to the
                      <a
                        href="../../SiteAssets/APIMarketplace/Gfx/Privacy-Notice-API-Market-Place.pdf"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      Note: An activation link will be send to your registered
                      mail id, Please click the link to activate.
                    </p>
                  </li>
                  <li>
                    <div className="apim-pop-button-w comm-button-wrap">
                      <button
                        className="ui button comm-button-style primary apim-pop-button-create"
                        id="createJU"
                        onClick={handleSubmit}
                        disabled={!isFormFieldsValid}
                      >
                        {isCreateButtonActive === true
                          ? "JOINING..."
                          : "CREATE"}
                      </button>
                      <button
                        className="ui button comm-button-style secondary custom-loader"
                        id="loadingJU"
                        style={{ display: "none" }}
                      >
                        <i className="icon-plus iconLoader" />
                        Joining..
                      </button>

                      {isCreateButtonActive === true ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <></>
                      )}
                      <span className="visually-hidden">Loading...</span>
                      <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                      <button
                        className="ui button comm-button-style secondary apim-pop-button-cancel"
                        id="cancelJU"
                        formNoValidate
                        onClick={navigateHome}
                      >
                        Cancel
                      </button>
                    </div>
                  </li>
                </ul>
              </section>
            </div>
            <div className="toast-container">
              <ToastContainer limit={2} />
            </div>
          </div>
        );
    
}

export default JoinUs;





